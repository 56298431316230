
import React from 'react';
import Hero from 'blocks/Hero';
import Text from 'blocks/Text';
import Default from 'blocks/Default';

const availableBlocks = {
  'Hero': Hero,
'Text': Text,
};

const Blocks = ({block}) => {
    const blockName = block.__typename.replace(/.*_([a-z]*)$/gi, '$1');

    return availableBlocks[blockName]
        ?  React.createElement(availableBlocks[blockName], {Blocks, ...block})
        : <Default blockType={blockName}/>;
};

export default Blocks;
